import { Magic } from "magic-sdk";
import Api from "@/services/Strapi.js";
import router from "@/router";
const m = new Magic(process.env.VUE_APP_MAGIC_KEY, {
  locale: "es",
  network: "mainnet"
});

const state = () => ({
  user: null,
  isLogged: false,
  strapiUserData: {},
  ethAddress: null,
  authLoading: false
});

const getters = {
  authToken: state => {
    return state.user.idToken;
  },
  userId: state => {
    return (state.strapiUserData && state.strapiUserData.id) || false;
  },
  authLoading: state => {
    return state.authLoading;
  },
  strapiUserData: state => {
    return state.strapiUserData;
  }
};

const actions = {
  async login({ commit, dispatch }, email) {
    commit("SET_AUTH_LOADING", true);
    const savedRedirect = localStorage.getItem("redirect");
    if (savedRedirect) {
      await m.auth.loginWithMagicLink({
        ...email,
        ...{ redirectURI: savedRedirect }
      });
    } else {
      await m.auth.loginWithMagicLink({
        ...email,
        ...{ redirectURI: window.location.href }
      });
    }

    const data = await m.user.getMetadata();
    const idToken = await m.user.getIdToken({ lifespan: 900000000000 });
    commit("SET_USER_DATA", { data, idToken });
    return dispatch("getMyUserData");
  },
  async loginWithCredentials({ commit, dispatch }, credentials) {
    await m.auth.loginWithCredential(credentials);
    const data = await m.user.getMetadata();
    const idToken = await m.user.getIdToken({ lifespan: 900000000000 });
    commit("SET_USER_DATA", { data, idToken });
    return dispatch("getMyUserData");
  },
  getMyUserData() {
    return Api.get("/users/me");
  },
  refreshUserData({ commit }) {
    return Api.get("/users/me").then(resp => {
      commit("SET_STRAPI_USER_DATA", resp.data);
    });
  },
  async updateMe({ commit }, payload) {
    return Api.put(`/updateMe`, payload).then(res => {
      commit("SET_STRAPI_USER_DATA", res.data);
    });
  },
  async logout({ commit }) {
    commit("SET_AUTH_LOADING", true);
    await m.user.logout();
    commit("CLEAR_USER_DATA");
    commit("app/SET_ALL_DATA_LOADED", false, { root: true });
  }
};

// mutations
const mutations = {
  SET_AUTH_LOADING(state, authLoadingState) {
    state.authLoading = authLoadingState;
  },

  SET_USER_DATA(state, userData) {
    state.user = userData;
    state.isLogged = true;
    localStorage.setItem("user", JSON.stringify(userData));
  },
  SET_STRAPI_USER_DATA(state, strapiUserData) {
    state.strapiUserData = strapiUserData;
    state.authLoading = false;
  },
  CLEAR_USER_DATA(state) {
    state.user = null;
    state.strapiUserData = {};
    state.isLogged = false;
    localStorage.removeItem("user");
    router.push({ name: "Login" });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
