export default {
  strict: false,
  namespaced: true,

  state () {
    return {
      isDevEnvironment: false,
      allDataLoaded: false,
      siteURL: null,
    }
  },

  getters: {
    isDevEnvironment: state => state.isDevEnvironment,
    apiSufix: state => state.isDevEnvironment && "dev" || "v1",
    siteURL: state => state.siteURL,
    allDataLoaded: state => state.allDataLoaded,
  },
  mutations: {
    SET_DEV_ENV (state, value) {
      state.isDevEnvironment = value
    },
    SET_SITE_URL (state, value) {
      state.siteURL = value
    },
    SET_ALL_DATA_LOADED (state, value) {
      state.allDataLoaded = value
    },
  },
  actions: {
    setAllDataLoaded({commit}, payload) {
        commit('SET_ALL_DATA_LOADED', payload)
    },
  }
}
