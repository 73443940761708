import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import { SDKError, RPCError, ExtensionError } from "magic-sdk";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/dashboard/samples" },
  {
    path: "/auth",
    component: () => import("@/views/auth/Index"),
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/auth/Login"),
        meta: {}
      }
    ]
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/app/components/core/View"),
    children: [
      {
        name: "Samples",
        path: "samples",
        component: () => import("@/views/app/samples/SamplesList"),
        meta: {
          breadcrumbs: [{ text: "Muestras" }]
        }
      },
      {
        name: "SampleGXGenesResults",
        path: "samples/:sampleId/genes-results",
        component: () =>
          import("@/views/app/samples/GXGeneResults/SampleGXGenesResultsList"),
        props: true,
        meta: {
          breadcrumbs: [
            { text: "Muestras", to: { name: "Samples" } },
            { text: ":sampleId" },
            { text: "Resultados Genes" }
          ]
        }
      },
      {
        name: "SampleGXSnpsResults",
        path: "samples/:sampleId/snps-results",
        component: () =>
          import("@/views/app/samples/GXSnpResults/SampleGXSnpsResultsList"),
        props: true,
        meta: {
          breadcrumbs: [
            { text: "Muestras", to: { name: "Samples" } },
            { text: ":sampleId" },
            { text: "Resultados SNPs" }
          ]
        }
      },
      {
        name: "SampleGXDrugsResults",
        path: "samples/:sampleId/drugs-results",
        component: () =>
          import("@/views/app/samples/GXDrugResults/SampleGXDrugsResultsList"),
        props: true,
        meta: {
          breadcrumbs: [
            { text: "Muestras", to: { name: "Samples" } },
            { text: ":sampleId" },
            { text: "Resultados Medicamentos" }
          ]
        }
      },
      {
        name: "GXGenes",
        path: "gx-genes",
        component: () => import("@/views/app/gx_genes/GXGenesList"),
        meta: {
          breadcrumbs: [{ text: "GXGenes" }]
        }
      },
      {
        name: "FAQs",
        path: "faqs",
        component: () => import("@/views/app/faqs/FAQsList"),
        meta: {
          breadcrumbs: [{ text: "FAQs" }]
        }
      },
      {
        name: "GenotyperEntries",
        path: "genotyper-entries",
        component: () =>
          import("@/views/app/genotyper_entries/GenotyperEntriesList"),
        meta: {
          breadcrumbs: [{ text: "Entradas Genotyper" }]
        }
      },
      {
        name: "KBDrugs",
        path: "kb-drugs",
        component: () => import("@/views/app/kb_drugs/KBDrugsList"),
        meta: {
          breadcrumbs: [{ text: "Medicamentos" }]
        }
      },
      {
        name: "KBDrugDetail",
        path: "kb-drugs/:drugId",
        props: true,
        component: () => import("@/views/app/kb_drugs/KBDrugEdit"),
        meta: {
          breadcrumbs: [
            { text: "Medicamentos", to: { name: "KBDrugs" } },
            { text: ":drugId" }
          ]
        }
      },
      {
        name: "EPGDrugDetail",
        path: "epg-drugs/:drugId",
        props: true,
        component: () => import("@/views/app/epg_drugs/EPGDrugEdit"),
        meta: {
          breadcrumbs: [
            { text: "Medicamentos", to: { name: "KBDrugs" } },
            { text: ":drugId" }
          ]
        }
      },
      {
        name: "EPGGeneDetail",
        path: "epg-genes/:geneId",
        props: true,
        component: () => import("@/views/app/epg_genes/EPGGeneEdit"),
        meta: {
          breadcrumbs: [
            { text: "Genes", to: { name: "KBGenes" } },
            { text: ":geneId" }
          ]
        }
      },
      {
        name: "KBGenes",
        path: "kb-genes",
        component: () => import("@/views/app/kb_genes/KBGenesList"),
        meta: {
          breadcrumbs: [{ text: "Genes" }]
        }
      },
      {
        name: "KBGeneDetail",
        path: "kb-genes/:geneId",
        props: true,
        component: () => import("@/views/app/kb_genes/KBGeneEdit"),
        meta: {
          breadcrumbs: [
            { text: "Genes", to: { name: "KBGenes" } },
            { text: ":geneId" }
          ]
        }
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "*",
    component: () => import("@/views/auth/Index"),
    children: [
      {
        name: "404 Error",
        path: "",
        component: () => import("@/views/app/Error")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  if (to.query.magic_credential) {
    let query = Object.assign({}, to.query);
    delete query.magic_credential;
    router.replace({ query });
  }
});

router.beforeEach((to, from, next) => {
  if (to.query.magic_credential) {
    store.commit("auth/SET_AUTH_LOADING", true);
    store
      .dispatch("auth/loginWithCredentials", to.query.magic_credential)
      .then(resp => {
        store.commit("auth/SET_STRAPI_USER_DATA", resp.data);
        next({
          path: "/"
        });
      })
      .catch(err => {
        if (err instanceof SDKError) {
          console.log(err);
        }
        if (err instanceof RPCError) {
          console.log(err);
        }
        if (err instanceof ExtensionError) {
          console.log(err);
        }
      });
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!localStorage.getItem("user")) {
        return next({
          path: "/auth/login"
        });
      }
      return next();
    }
    return next();
  }
});

export default router;
