import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persist-indexeddb";
import auth from "./modules/auth";
import app from "./modules/app";

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ["auth", "app"]
});

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg",
    drawer: null
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    }
  },
  actions: {},
  modules: {
    auth,
    app
  },
  plugins: [dataState]
});

export default store;
