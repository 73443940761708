import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/lib/locale/es";
import "@/sass/overrides.sass";

Vue.use(Vuetify);

const theme = {
  //primary: '#6F4AE6',
  primary: "#4D2CB9",
  secondary: "#9077E6",
  darkprimary: "#1C093A",
  accent: "#5DFFBD",
  info: "#00CAE3"
};

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es"
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
});
